<template>
    <div class="dashboard-coverage" @click="navigateToFixture">
      <div class="team-info">
        <img :src="matchInfo.team1Logo" alt="Team 1 Logo" class="team-logo">
        <h2>{{ matchInfo.team1Name }} vs. {{ matchInfo.team2Name }}</h2>
        <img :src="matchInfo.team2Logo" alt="Team 2 Logo" class="team-logo">
      </div>
      <p>{{ $t('score') }}: {{ matchInfo.scoreTeam1 }} - {{ matchInfo.scoreTeam2 }}  ({{ matchInfo.time_elapsed }}`)</p>
      <div class="events">
        <h3>{{ $t('events') }}:</h3>
        <ul>
          <li v-for="event in matchInfo.events" :key="event.id">
            {{ event.time }}' - {{ event.type }} by {{ event.player }} ({{ event.team }})
          </li>
        </ul>
      </div>
    </div>
  </template>
  
  
  <script>
  export default {
    name: 'DashBoardCoverage',
    props: {
      matchInfo: {
        type: Object,
        default: () => ({})
      }
    },
    methods: {
        navigateToFixture() {
          this.$router.push(`/fixture/${this.matchInfo.id}`);
        }
    }
  }
  </script>
  
  <style>
  .team-info {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 10px; /* Adjust the space between logos and the title */
  margin-bottom: 15px;
}

.team-logo {
  width: 50px; /* Size of the logo */
  height: 50px; /* Size of the logo */
  border-radius: 50%; /* Makes the image circular */
  object-fit: cover; /* Ensures the image covers the area without distortion */
}

/* Add other styles you've previously defined here */

  .dashboard-coverage {
  font-family: 'Roboto', sans-serif;
  background: #f7f7f7;
  border-radius: 10px;
  box-shadow: 0 8px 20px rgba(0, 0, 0, 0.12);
  padding: 20px;
  margin: 30px auto;
  max-width: 90%;
  text-align: center;
}

.dashboard-coverage h2 {
  color: #2c3e50;
  font-size: 1.75rem;
  margin-bottom: 0.5rem;
  font-weight: 500;
  letter-spacing: 0.5px;
}

.dashboard-coverage p {
  color: #34495e;
  font-size: 1.25rem;
  margin-bottom: 1.5rem;
}

.events {
  background: #fff;
  border: 1px solid #eaeaea;
  padding: 15px;
  border-radius: 8px;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.05);
}

.events h3 {
  color: #2980b9;
  font-size: 1.5rem;
  margin-bottom: 1rem;
  font-weight: 500;
}

.events ul {
  list-style: none;
  padding: 0;
  margin: 0;
}

.events li {
  background: #ecf0f1;
  color: #333;
  border-left: 4px solid #3498db;
  margin-bottom: 10px;
  padding: 10px;
  border-radius: 5px;
  transition: all 0.3s ease;
  cursor: pointer;
}

.events li:hover {
  background: #3498db;
  color: #fff;
  border-left-color: #2980b9;
}

@media (max-width: 768px) {
  .dashboard-coverage {
    margin: 20px;
    padding: 15px;
  }

  .dashboard-coverage h2 {
    font-size: 1.5rem;
  }

  .dashboard-coverage p {
    font-size: 1rem;
  }

  .events h3 {
    font-size: 1.25rem;
  }
}

  </style>
  