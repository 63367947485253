<template>
  <v-app>
     <link href="https://cdnjs.cloudflare.com/ajax/libs/font-awesome/5.15.3/css/all.min.css" rel="stylesheet">
     <v-main>
        <router-view/>
     </v-main>
  </v-app>
</template>

<script setup>
import { onMounted } from 'vue';

onMounted(() => {
  document.title = 'OffsideCompass';
  // Create link element for favicon
  let link = document.querySelector("link[rel~='icon']");
  if (!link) {
    link = document.createElement('link');
    link.rel = 'icon';
    document.getElementsByTagName('head')[0].appendChild(link);
  }
  // Set the favicon image file (e.g., favicon.png)
  link.href = '/logo.png'; // Provide the correct path to your favico
});

</script>
<style>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
}

nav {
  padding: 30px;
}

nav a {
  font-weight: bold;
  color: #2c3e50;
}

nav a.router-link-exact-active {
  color: #42b983;
}
</style>

<style scoped>
  @import "./scss/main.scss";
</style>