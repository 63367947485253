<template>
  <div class="sidebar">
    <div class="sidebar-content" :class="{ 'sidebar-hidden': !isSidebarVisible }">
      <ul>
      <li v-for="item in menuItems" :key="item.text">
        <router-link :to="item.path" class="menu-item">
          <!-- Combined icon and text container -->
          <div class="menu-item-content">
            <!-- Render img tag if icon is an image (SVG) -->
            <img v-if="isImage(item.icon)" :src="item.icon" class="icon"/>
            <!-- Render i tag for Font Awesome icons -->
            <i v-else :class="['icon', item.icon]"></i>
            <!-- Text label -->
          </div>
        </router-link>
      </li>
    </ul>
    </div>
  </div>
</template>

<script>
  export default {
    name: 'Sidebar',
    data() {
      return {
        isSidebarVisible: true, 
        menuItems: [
          { text: 'Greece', path: '/articles/category/Greece/1', icon: '/gr.svg' },
          { text: 'Spain', path: '/articles/category/Spain/1', icon: '/es.svg' },
          { text: 'England', path: '/articles/category/England/1', icon: '/en.png' },
          { text: 'Germany', path: '/articles/category/Germany/1', icon: '/de.svg' },
          { text: 'Italy', path: '/articles/category/Italy/1', icon: '/it.svg' },
          { text: 'France', path: '/articles/category/France/1', icon: '/fr.svg'},
          { text: 'World', path: '/articles/category/World/1', icon: 'fas fa-globe' },
          { text: 'Europe', path: '/articles/category/Europe/1', icon: 'fas fa-euro-sign' },
        ]
      }
    },
    methods: {
      isImage(icon) {
        return /\.(svg|png|jpg|jpeg|gif)$/.test(icon);
      }
    }
  }
</script>

  
<style scoped>

.sidebar {
  position: fixed;
  top: 50%;
  left: 0;
  width: 50px; /* Adjusted width */
  transform: translateY(-50%);
  background-color: #262626; /* Modern dark shade */
  z-index: 1000;
  transition: width 0.3s ease;
  transition: left 0.3s ease;
  border-radius: 10px; /* Rounded corners for sidebar */
  box-shadow: 0 4px 6px rgba(0,0,0,0.1);
}

.sidebar-content-hidden{
  left: -200px;
}

.sidebar ul {
  list-style-type: none;
  margin: 0;
  padding: 0;
  overflow-x: hidden; /* Prevent horizontal overflow */
}

.menu-item {
  color: #fff; /* White text color */
  text-decoration: none; /* Remove underline from links */
}

.menu-item-content {
  display: flex;
  align-items: center;
  padding: 10px;
  border-radius: 5px;
  transition: background-color 0.2s ease; /* Smooth background transition */
}

.icon {
  width: 20px;
  height: 20px;
  margin-right: 10px; /* Spacing between icon and label */
  border-radius: 50%; /* Makes icons circular, adjust as needed */
  box-shadow: 0 2px 4px rgba(0,0,0,0.05); 
}

.menu-label {
  white-space: nowrap; /* Prevent wrapping and keep the text in a single line */
  opacity: 0; /* Hide text initially */
  transition: opacity 0.3s ease; /* Smooth transition for text opacity */
}

.sidebar:hover .menu-label {
  opacity: 1; /* Show text when sidebar is expanded */
}

.sidebar ul li:hover .menu-item-content {
  background-color: #333; /* Highlight for hovered items */
}

@media screen and (max-width: 768px) {
  .sidebar {
    display: none; /* Hide component for mobile devices */
  }
}
</style>
