import { createRouter, createWebHistory } from 'vue-router'
import Dashboard from '../views/Dashboard.vue'

const routes = [
  {
    path: '/',
    name: 'home',
    component: Dashboard
  },
  {
    path: '/article/:id',
    name: 'Article',
    component: () => import('../views/Article.vue')
  },
  {
    path: '/search/:query/:page',
    name: 'Search',
    component: () => import('../views/Search.vue'),
  },
  {
    path: '/articles/category/:category/:page',
    name: 'CategoryArticles',
    component: () => import('../views/Category.vue'),
  },
  {
    path: '/articles/hashtag/:hashtag/:page',
    name: 'HashtagArticles',
    component: () => import('../views/HashTag.vue'),
  },
  {
    path: '/fixture/:id',
    name: 'Fixture',
    component: () => import('../views/FixtureDetails.vue'),
  },
  {
    path: '/login',
    name: 'Login',
    component: () => import('../views/Login.vue'),
  },
  {
    path: '/register',
    name: 'Register',
    component: () => import('../views/Register.vue'),
  },
  {
    path: '/dashboard',
    name: 'Dashboard',
    component: () => import('../views/PersonalDashboard.vue'),
  }
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

export default router
