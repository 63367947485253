<template>
  <div>
    <span class="navGrey"><b>live</b><span class="blue"><b>Scores</b></span></span><br>  
    <div v-if="loading" class="loading">Loading...</div>
    <div v-if="error" class="error">{{ error }}</div>
    <div v-if="scores" class="leagues-container">
      <div v-for="league in scores" :key="league.league" class="league">
        <div class="league-header">
          <h3 class="league-name">{{ league.league }}</h3>
          <button @click="toggleLeagueVisibility(league.league)" class="toggle-league">Show/Hide</button>
        </div>
        <ul class="fixtures-list" v-show="leaguesVisible[league.league]">
          <li v-for="fixture in league.fixtures" :key="fixture.id" class="live-score-item" @click="navigateToFixture(fixture.id)">
            <strong>
              {{ fixture.home_team }} {{ fixture.status === 'FT' || fixture.status === '1H' || fixture.status === '2H' ? fixture.home_goals : '' }} - {{ fixture.status === 'FT' || fixture.status === '1H' || fixture.status === '2H' ? fixture.away_goals : '' }} {{ fixture.away_team }}
              <span v-if="fixture.status === '1H' || fixture.status === '2H'" class="live-text">Live</span>
              <span v-else-if="fixture.status === 'FT'" class="finished-text">Finished</span>
              <span v-else-if="fixture.status === 'PST'" class="postponed-text">Postponed</span>
            </strong>
          </li>
        </ul>
      </div>
    </div>
  </div>
</template>
<script>
  import axios from 'axios';
  
  export default {
    data() {
      return {
        scores: null,
        error: null,
        loading: false,
        leaguesVisible: {},
        toShow : [4, 21, 1, 803, 2 ,3 ,19, 9, 199, 197, 140, 556, 143, 39, 40, 78, 135, 61]
      };
    },
    mounted() {
      this.fetchScores();
    },
    methods: {
      navigateToFixture(fixtureId) {
        this.$router.push(`/fixture/${fixtureId}`);
      },
      toggleLeagueVisibility(leagueName) {
        this.leaguesVisible[leagueName] = !this.leaguesVisible[leagueName];
      },
      fetchScores() {
        this.loading = true;
        axios.get(process.env.VUE_APP_BACKEND_URL + '/live/fixtures')
          .then(response => {
            const sortedScores = response.data.sort((a, b) => {
              const indexA = this.toShow.indexOf(a.league_id);
              const indexB = this.toShow.indexOf(b.league_id);

              if (indexA !== -1 && indexB !== -1) {
                return indexA - indexB;
              }

              if (indexA !== -1) {
                return -1;
              }
              if (indexB !== -1) {
                return 1;
              }

            return a.league_id - b.league_id;
           });
            this.scores = sortedScores;
          })
          .catch(error => {
            console.error('Error fetching live scores:', error);
            this.error = 'Failed to load live scores.';
          })
          .finally(() => {
            this.loading = false;
          });
          console.log(this.scores )
      }
    }
  };
</script>
  
<style>
.leagues-container {
  max-height: 500px; /* Adjust this value based on your needs */
  overflow-y: auto; /* Enables vertical scrolling */
}

/* Style adjustments for scrollbars (optional but recommended for aesthetics) */
.leagues-container::-webkit-scrollbar {
  width: 8px;
}

.leagues-container::-webkit-scrollbar-track {
  background: #f1f1f1;
}

.leagues-container::-webkit-scrollbar-thumb {
  background: #888;
}

.leagues-container::-webkit-scrollbar-thumb:hover {
  background: #555;
}
.league-header {
  display: flex; /* Enables flexbox layout */
  align-items: center; /* Vertically centers the items in the container */
  justify-content: space-between; /* Places maximum space between the items */
}

.league-name {
  margin: 0; /* Removes default margin from h3 to better control spacing */
  flex-grow: 1; /* Allows the league name to take up any extra space */
}

.toggle-league {
  margin-left: 10px; /* Adds some space between the league name and the button */
}
.live-text {
  color: red; /* Sets the color of "Live" text to red */
}
.finished-text {
  color: green; /* Sets the color of "Finished" text to green */
}
.league-name {
  color: #04AA6D; /* Example league title color */
  margin: 20px 0;
}
/* Base styles for the component */
/* Base styles for the component */
.title_color {
  text-align: center;
  color: #154561; /* Specified color */
  font-size: 24px;
  margin-bottom: 20px;
  text-decoration: underline; /* Adds underline to title */
}

.live-score-item {
  background-color: #222; /* Darker background for modern look */
  color: #fff; /* White text for readability */
  margin-bottom: 1rem;
  padding: 1rem 1.5rem; /* Responsive padding for a spacious look */
  border-radius: 0.5rem; /* Rounded corners */
  box-shadow: 0 5px 15px rgba(0, 0, 0, 0.5); /* Deeper shadow for depth */
  display: flex;
  justify-content: center; /* Center horizontally */
  align-items: center; /* Center vertically */
  transition: transform 0.3s ease, box-shadow 0.3s ease;
}

strong {
  font-size: 1.1rem; /* Slightly larger font size */
  font-weight: 600; /* Semi-bold font weight */
  text-align: center; /* Ensure text is centered if it wraps */
}


.live-score-item:hover {
  transform: translateY(-3px); /* Subtle lift effect on hover */
  box-shadow: 0 10px 20px rgba(0, 0, 0, 0.5); /* Enhanced shadow on hover */
}

/* Modern sans-serif font with a fallback */
body {
  font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
}

/* Additional modern touches using pseudo-elements */
.live-score-item::before {
  content: '';
  display: block;
  width: 6px;
  height: 100%;
  background-color: #04AA6D; /* Accent color bar on the side */
  border-radius: 1rem 0 0 1rem; /* Rounded left edge */
}

/* Responsive typography and spacing */
@media (max-width: 768px) {
  .title_color {
    font-size: 20px;
  }

  .live-score-item {
    padding: 0.75rem 1rem; /* Adjust padding on smaller screens */
  }

  .live-score-item strong {
    font-size: 1rem; /* Adjust font size on smaller screens */
  }
}

/* Custom scrollbar styling */
.live-scores-list {
  max-height: 500px;
  overflow-y: auto;
}

/* Add custom scrollbar styles only if necessary */
.live-scores-list::-webkit-scrollbar {
  width: 10px;
}

.live-scores-list::-webkit-scrollbar-track {
  background: #f1f1f1;
}

.live-scores-list::-webkit-scrollbar-thumb {
  background: #888;
}

.live-scores-list::-webkit-scrollbar-thumb:hover {
  background: #555;
}


</style>
