<template>
    <div class="home">
      <div class="latest-news-container">
        <div class="latest-news-header">
          <span class="navGrey"><b>  {{ $t('latestNotCapital') }} </b></span>
          <span class="blue"><b>{{ $t('news') }} </b></span><br>
          <div class="latest-news-item" v-for="(item, index) in data" :key="item.id">
            <h4>
              <router-link :to="'/article/' + item.id" class="article-link">
                <time-ago :datetime="new Date(item.date)" class="time-stamp"></time-ago>
                <span class="number-circle">{{ index + 1 }}</span>
                <a target="_blank"> {{ item.title }} </a>
              </router-link>
            </h4>
          </div>
        </div>
      </div>
    </div>
  </template>
  
  <script>
  import axios from 'axios'
  import TimeAgo from 'vue2-timeago'
  export default {
    data(){
      return {
        data: [],
      }
    },
    components: {//{{ item.createdAt }}
      TimeAgo,
    },
    methods: {
      async requestDashboard() {
      axios.get(process.env.VUE_APP_BACKEND_URL + '/latest/articles')
        .then(response => {
          this.data = response.data; // Assign the array to this.data
        })
        .catch(function (error) {
          console.log(error);
        });
    },
    },
    mounted(){
      this.requestDashboard()
    }
  }
  </script>