import { createApp } from 'vue';
import App from './App.vue';
import router from './router';
import { createVuetify } from 'vuetify';
import 'vuetify/dist/vuetify.min.css'; // Ensure you are using css-loader
import { createI18n } from 'vue-i18n';

const app = createApp(App);
const vuetify = createVuetify(); // Create a new instance of Vuetify

import gr from './locales/gr.json';

// Create the i18n instance with the locale messages
const i18n = createI18n({
    locale: 'gr', // set default locale
    fallbackLocale: 'gr', // set fallback locale
    messages: {
      gr
    }
  });

app.use(i18n);
app.use(router);
app.use(vuetify); // Use the Vuetify instance

app.mount('#app');
