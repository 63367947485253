<template>
  <div class="dashboard-main" v-if="isDataLoaded" >
    <Header />
    <br><br>
    
    <div class="content-centered">
      <DashBoardCoverage :matchInfo="matchInfo" />
    </div>
  
    <Sidebar />
    <div class="content">
      <LiveList />
      <LatestNews />
    </div>
    <Footer />
  </div>
</template>

<script>
import LatestNews from '@/components/dashboard/LatestNews.vue'
import LiveList from '@/components/dashboard/LiveList.vue'
import Header from '@/components/shared/Header.vue'
import Footer from '@/components/shared/Footer.vue'
import Sidebar from '@/components/shared/Sidebar.vue'
import DashBoardCoverage from '@/components/DashBoardCoverage.vue'
//import ImportantNews from '@/components/dashboard/ImportantNews.vue'
import axios from 'axios'

export default {
  components: {
    Header,
    Footer,
    LatestNews,
    Sidebar,
    LiveList,
    DashBoardCoverage,
    //ImportantNews
  },
  data(){
    return {
      data:{} ,
      isDataLoaded: false,
      isImportantArticlesLoaded: false,
      matchInfo: {},
    }
  },
  methods: {
    processMatchData(data) {
      this.matchInfo.id = data.fixture_id;
      this.matchInfo.team1Logo = data.fixture_data.data.home_team_logo;
      this.matchInfo.team2Logo = data.fixture_data.data.away_team_logo;
      this.matchInfo.team1Name = data.fixture_data.data.home_team;
      this.matchInfo.team2Name = data.fixture_data.data.away_team;
      this.matchInfo.scoreTeam1 = data.fixture_data.data.home_goals;
      this.matchInfo.scoreTeam2 = data.fixture_data.data.away_goals;
      this.matchInfo.time_elapsed = data.fixture_data.data.time_elapsed;
      let events = [];

      for (let i = 0; i < data.fixture_data.data.events.length; i++) {
        let type = 'goal'; // Default type
        if (data.fixture_data.data.events[i].type === 'Goal') {
          type = 'goal';
        } else if (data.fixture_data.data.events[i].type === 'Card') {
          if (data.fixture_data.data.events[i].detail === 'Red Card') {
            type = 'red card';
          } else if (data.fixture_data.data.events[i].detail === 'Yellow Card') {
            continue; // Skip yellow cards
          }
        } else if (data.fixture_data.data.events[i].type === 'subst' || data.fixture_data.data.events[i].type === 'Var') {
          continue; // Skip substitutions and VAR events
        }

        let eventObj = {
          team: data.fixture_data.data.events[i].team.name,
          type: type,
          time: data.fixture_data.data.events[i].time.elapsed,
          player: data.fixture_data.data.events[i].player.name
        };
        events.push(eventObj);
      }
      this.matchInfo.events = events;
    },
    async loadDashboardData(id) {
      axios.get('https://orca-app-qpqer.ondigitalocean.app/' + '/dashboard/' + id)
        .then(response => {
          this.data = response.data;
          this.isImportantArticlesLoaded = true;
          this.isDataLoaded = true;
          //this.id = this.data.fixtureDetails.fixture_id
          console.log(this.data);
          this.processMatchData(this.data);

        })
        .catch(function (error) {
          console.log(error);
        });
      }
    },
    mounted(){
      this.loadDashboardData(1052562);
    }
  }
</script>

<style>
  .dashboard-main {
    display: flex;
    flex-direction: column;
    min-height: 100vh;
  }
  
  .template {
    display: flex;
    flex-direction: column;
    min-height: 100vh;
  }

  .content {
    display: flex;
    justify-content: center;
    margin: 40px 0;
    gap: 40px;
  }

  .content-centered {
    display: flex;
    justify-content: center;
    margin: 40px 0;
  }

  .contenttwo {
    display: flex;
    justify-content: center;
    margin: 40px auto;
    gap: 40px;
    width: 50%;
  }

  .live, .latest-news {
    max-width: 600px;
  }

  .live {
    background-color: #f2f2f2;
    padding: 20px;
    border-radius: 5px;
    box-shadow: 0 4px 8px rgba(0,0,0,0.1);
  }

/* Make sure the layout is responsive */
@media screen and (max-width: 768px) {
  .content {
    flex-direction: column;
  }

  .live, .latest-news {
    margin: 10px 0;
    max-width: 100%;
  }
}
</style>
