<template>
  <!-- footer -->
  <footer class="footer">
    <div class="footer-container">
      offsidecompass ©2024
    </div>
  </footer>
  <!-- end of footer -->
</template>

<style scoped>
.footer {
	flex-shrink: 0;
	flex-grow: 0;
	position: relative;
	bottom: 0;
	background-color: #154561;
	text-align: center;
	padding: 20px 0;
	width: 100%;
	border-top-left-radius: 10px;
	border-top-right-radius: 10px;
	margin-top: auto; /* Push the footer to the bottom */
}
  
.footer-container {
	margin: 0 auto;
	padding: 10px;
	font-size: 1.0em;
	display: flex;
	justify-content: center;
	align-items: center;
	height: 100%;
}
</style>