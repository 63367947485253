<template>
  <div class="header">
    <!-- Hamburger Menu Button -->
    <button class="hamburger" @click="toggleMenu" :class="{'is-active': menuActive}">
      <i class="fa fa-bars"></i>
    </button>

    <!-- Logo -->
    <router-link :to="'/'" class="logo">
      <span class="nav-text">offside<span class="blue">Compass</span></span>
    </router-link>

    <!-- Navigation Links -->
    <div class="topnav" :class="{'is-active': menuActive}">
      <template v-if="token">
        <router-link :to="'/dashboard'">{{ $t('home') }}</router-link>
        <router-link @click="logout" to="#">{{ $t('logout') }}</router-link>
      </template>
      <template v-else>
        <router-link :to="'/login'">{{ $t('login') }}</router-link>
        <router-link :to="'/register'">{{ $t('register') }}</router-link>
      </template>

      <!-- Search Form -->
      <form @submit.prevent="search" class="search-container">
        <input type="text" placeholder="Search..." v-model="searchQuery" name="search">
        <button type="submit"><i class="fa fa-search blue"></i></button>
      </form>
    </div>
  </div>
</template>

<script>
export default {
  name: 'Header',
  data() {
    return {
      searchQuery: '',
      menuActive: false
    };
  },
  computed: {
    token() {
      return localStorage.getItem('token');
    }
  },
  methods: {
    toggleMenu() {
      this.menuActive = !this.menuActive;
    },
    search() {
      this.$router.push(`/search/${encodeURIComponent(this.searchQuery)}/1`);
    },
    logout() {
      localStorage.removeItem('token');
      this.$router.push('/');
    }
  }
};
</script>

<style scoped>
.header {
  background-color: #181b1e;
  padding: 0 20px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.logo {
  display: inline-block;
}

.nav-text {
  color: #ffffff;
  font-weight: bold;
}

.highlight {
  color: #0078f0;
}

.topnav {
  display: flex;
  align-items: center;
}

.topnav.router-link-exact-active {
  color: #42b983;
}

.topnav a {
  color: #ffffff;
  padding: 10px 15px;
  text-decoration: none;
  font-size: 14px;
  font-weight: 600;
}

.search-container {
  display: flex;
}

.search-container input[type=text] {
  padding: 6px;
  border: none;
  border-radius: 20px;
 color : white;
}

.search-container button {
  padding: 6px 10px;
  background: #0078f0;
  color: white;
  border: none;
  border-radius: 20px;
  cursor: pointer;
}

.hamburger {
  background: none;
  border: none;
  cursor: pointer;
  padding: 0;
  display: none;
}

.hamburger.is-active .fa-bars {
  transform: rotate(45deg);
}

.hamburger .fa-bars {
  color: #ffffff;
  font-size: 24px;
}

@media screen and (max-width: 768px) {
  .header {
    justify-content: space-between; /* Ensures space between items */
  }
  .hamburger {
    display: block;
    margin-left: auto;
  }

  .topnav {
    flex-direction: column;
    overflow: hidden;
    max-height: 0;
    transition: max-height 0.3s ease-out;
    width: 100%;
  }

  .topnav.is-active {
    max-height: 500px; /* Adjust as needed */
  }

  .topnav a,
  .search-container {
    width: 100%;
    text-align: left;
  }

  .search-container {
    order: 3;
  }

  .search-container input[type=text],
  .search-container button {
    width: 100%;
    margin: 5px 0;
  }
}
</style>
